@import '../../../../scss/theme-bootstrap';

.content-block-signup {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  height: 100%;
  background-color: $color--black;
  color: $color--white;
  @include breakpoint($bp--large-up) {
    background-color: $color--white;
    color: $color--black;
  }
  .content-block-sitewide-banner--enabled & {
    display: block;
    height: 100%;
  }
  .site-wide-banner--disabled & {
    display: none;
  }
  &__gdpr {
    display: flex;
    margin-bottom: 14px;
    &.toggle {
      flex-direction: column;
      .content-block-signup__gdpr--label,
      .content-block-signup__disclaimer {
        padding-top: 10px;
      }
    }
    .content-block-signup__gdpr {
      &--checkbox {
        &[type='checkbox'] {
          position: relative;
          align-self: flex-start;
          margin-#{$rdirection}: 10px;
          display: flex;
          #{$ldirection}: unset;
          @include paint-sd-checkbox($color-red-shade, $color-red-shade, $color--white);
          transform: scale(1.1);
        }
      }
      &--label {
        color: $color-grey-lowcontrast;
        a {
          color: inherit;
          border-bottom: 1px solid $color-grey-lowcontrast;
          @include breakpoint($bp--large-up) {
            border-color: $color-border-lightgrey;
          }
        }
      }
    }
  }
  &__wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 16px;
    color: $color--white;
    @include breakpoint($bp--large-up) {
      color: $color--black;
    }
    .content-block-signup__disclaimer {
      &.content-block-signup__disclaimer-link {
        color: $color--white;
        display: inline-block;
        margin-top: 8px;
        @include breakpoint($bp--large-up) {
          color: $color--black;
        }
        &:hover {
          color: $color--white;
          border-color: $color--white;
          @include breakpoint($bp--large-up) {
            color: $color--black;
            border-color: $color--black;
          }
        }
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  &__content-text {
    @include body-text;
    margin-#{$ldirection}: 0;
    padding: 0;
    width: 80%;
    color: $color--white;
    @include breakpoint($bp--large-up) {
      padding: 0;
      width: initial;
      display: flex;
      text-align: initial;
      color: $color--black;
    }
    .collapsible-signup-block & {
      @include swap_direction(padding, 0 20% 0 30px);
      width: 100%;
      cursor: pointer;
    }
    .content-block-signup--column & {
      text-align: center;
    }
    p {
      margin: 0;
    }
    a {
      border-bottom: 1px solid;
      border-color: inherit;
      color: inherit;
      font-size: 12px;
      padding-bottom: 1px;
      @include breakpoint($bp--large-up) {
        padding-bottom: 2px;
      }
      &:hover {
        border-bottom: 1px solid $color--gray--dusty--disable;
        color: $color--gray--dusty--disable;
      }
    }
  }
  &__disclaimer,
  &__gdpr-toggle  {
    @include legal-text;
    border-bottom: 1px solid $color--darker-gray;
    text-transform: none;
  }
  &__gdpr-toggle  {
    padding: 0;
    width: fit-content;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid $color--black;
    }
  }
  &__messages {
    display: flex;
    order: 0;
    flex-direction: column;
    .content-block-signup__success div,
    .content-block-signup__already-signed-up {
      color: inherit;
      position: absolute;
      top: 16px;
      padding: 0px 40px;
      @include breakpoint($bp--large-up) {
        position: static;
        padding: 0;
      }
    }
  }
  &__error,
  &__gdpr-error {
    color: $color--error;
    font-size: 12px;
    line-height: 1.5;
    transition: all;
    margin-bottom: 8px;
    span {
      display: inline-block;
    }
  }
  &__success {
    @include body-text;
    color: $color--white;
    @include breakpoint($bp--large-up) {
      color: $color--black;
    }
  }
  &__fields {
    @include pie-clearfix;
    .content-block-signup--active-success & {
      display: none;
    }
  }
  .content-block-signup__field {
    @include body-text;
    &::placeholder {
      color: inherit;
      text-transform: none;
    }
    background: transparent;
    border: 1px solid $color--gray--dark;
    color: $color--black;
    padding: 10px;
    position: relative;
    text-transform: none;
    max-height: 35px;
    &.error {
      border: 1px solid $color--red;
      color: $color--error;
    }
    &:hover {
      border-color: $color--black;
    }
    &.non-empty {
      color: $color--gray--dark;
    }
  }
  &__submit,
  &__button-trigger {
    &.link--small {
      @include body-text--small-bold;
      background: transparent;
      border: 0;
      color: $color--black;
      display: block;
      min-width: 70px;
      position: relative;
      padding: 12px;
      text-decoration: none;
      @include breakpoint($bp--large-up) {
        color: $color--white;
      }
      &:hover {
        border: 0;
        color: $color--black;
        text-decoration: none;
        @include breakpoint($bp--large-up) {
          color: $color--white;
        }
      }
    }
  }
  &__form {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: $ano_regular-font;
    padding: 12px 0;
    &.content-block-signup--active-error {
      padding: 10px 0;
    }
    .content-block-signup--column & {
      margin: 0 auto;
      padding: 0 0 18px;
      text-align: center;
      width: 333px;
    }
  }
  &__logo {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
      width: 105px;
      height: 12px;
      border-bottom: none;
      margin-bottom: 18px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__header {
    display: flex;
    width: 100%;
    .icon {
      position: absolute;
      top: 16px;
      width: 20px;
      height: 20px;
      fill: $color--white;
      z-index: -1;
      @include breakpoint($bp--large-up) {
        display: none;
      }
      &.icon--chevron-down {
        #{$rdirection}: 15px;
        display: none;
      }
    }
  }
  &__content {
    width: 100%;
  }
  &__icon,
  &__button,
  &__continue-shopping,
  &__submit,
  &__button-trigger {
    border-radius: 35px;
    display: flex;
    justify-content: center;
    width: 100%;
    .content-block-signup__continue-shopping-link {
      @include body-text--small-bold;
      position: relative;
      padding: 16px;
      text-decoration: none;
      border-bottom: none;
      &:hover {
        color: $color--white;
        text-decoration: none;
      }
    }
  }
  &__button,
  &__continue-shopping {
    background-color: $color--white;
    @include breakpoint($bp--large-up) {
      background-color: $color--black;
    }
  }
  &__button {
    order: 1;
    &.disabled {
      background-color: $color-cta-grey;
    }
  }
  &__continue-shopping {
    margin-top: 16px;
    height: 45px;
  }
  p {
    margin: 0;
  }
  &.collapsible-signup-block {
    &.collapsed {
      .icon--chevron-down {
        display: block;
      }
    }
    &.expanded {
      .icon--chevron-down {
        display: none;
      }
    }
    .icon--email-signup {
      height: 24px;
      width: 24px;
      #{$ldirection}: 16px;
      top: 14px;
    }
    .content-block-signup__field { 
      border-color: $color--white;
      color: $color--white;
      &:hover {
        border-color: $color--white;
      }
      &.non-empty {
        color: $color--white;
      }
      &.error {
        border-color: $color--red;
      }
    }
  }
}
